import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const BottomDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin: 30px 0;
  padding: 0 20px;

  transition: 250ms opacity ease;
  opacity: ${props => props.opacity};

  & .details-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    width: calc(100% - 30px - 30px - 110px - 110px);
    margin: 0 auto;

    @media (max-width: 768px) {
      width: 100%;

      order: 1;
    }
  }

  & p {
    font-size: 24px;
    line-height: 27px;
    letter-spacing: 0em;

    color: rgba(250, 251, 246, 0.5);
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);

    margin: 0 15px;

    @media (max-width: 1300px) {
      font-size: 18px;
      line-height: 130%;
      letter-spacing: 0.04em;
    }
  }

  & .page-link {
    & a {
      width: 110px;
      height: 31px;

      display: block;

      border: 1px solid rgba(250, 251, 246, 0.5);
      box-sizing: border-box;
      border-radius: 1px;

      font-family: "LDNBloomsburyOldStyle-DemiBold", Times, "Times New Roman",
        serif;
      font-style: normal;
      font-weight: 500;

      font-size: 14px;
      line-height: 30px;

      text-align: center;
      letter-spacing: 0.09em;
      text-transform: uppercase;

      color: rgba(250, 251, 246, 0.5);

      transition: 150ms all ease;

      &:hover {
        background: rgba(250, 251, 246, 0.5);
        color: #1d1d1b;
        border: 1px solid transparent;
      }
    }

    @media (max-width: 768px) {
      display: none;
      position: absolute;
      bottom: 20px;

      &.previous-link {
        order: 11;
        left: 20px;
      }

      &.next-link {
        order: 12;
        right: 20px;
      }
    }
  }
`;

const MobileBottomDetailsContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
  }

  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: 20px;

  position: relative;

  & .page-link {
    & a {
      width: 110px;
      height: 31px;

      display: block;

      border: 1px solid rgba(250, 251, 246, 0.5);
      box-sizing: border-box;
      border-radius: 1px;

      font-family: "LDNBloomsburyOldStyle-DemiBold", Times, "Times New Roman",
        serif;
      font-style: normal;
      font-weight: 500;

      font-size: 14px;
      line-height: 30px;

      text-align: center;
      letter-spacing: 0.09em;
      text-transform: uppercase;

      color: rgba(250, 251, 246, 0.5);

      transition: 150ms all ease;

      &:hover {
        background: rgba(250, 251, 246, 0.5);
        color: #1d1d1b;
        border: 1px solid transparent;
      }
    }
  }
`;

const Indicators = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & li {
    background: none;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    background-color: #c4c4c4;

    &.active,
    &:hover {
      background: rgba(250, 251, 246, 0.5);
    }
  }
`;

export const EventNavigation = ({
  opacity,
  prevVideoUid,
  nextVideoUid,
  imageCounter,
  isImage,
}) => {
  return (
    <>
      <BottomDetailsContainer opacity={opacity}>
        {prevVideoUid !== null && (
          <div className="page-link previous-link">
            <Link to={prevVideoUid}>Previous</Link>
          </div>
        )}

        {isImage && <Indicators>{imageCounter}</Indicators>}

        {/* <div className="details-container">
            {brand && <p className="brand-name">{brand}</p>}
            {clip && <p className="clip-name">{clip}</p>}
          </div> */}

        {nextVideoUid !== null && (
          <div className="page-link next-link">
            <Link to={nextVideoUid}>Next</Link>
          </div>
        )}
      </BottomDetailsContainer>

      <MobileBottomDetailsContainer opacity={opacity}>
        {prevVideoUid !== null && (
          <div className="page-link previous-link">
            <Link to={prevVideoUid}>Previous</Link>
          </div>
        )}

        {nextVideoUid !== null && (
          <div className="page-link next-link">
            <Link to={nextVideoUid}>Next</Link>
          </div>
        )}
      </MobileBottomDetailsContainer>
    </>
  );
};
