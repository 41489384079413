import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import ReactPlayer from "react-player/lazy";

// Components
import "../styles/gallery.css";
import { EventNavigation } from "../event/event-navigation";

const GalleryContainer = styled.div`
  position: relative;
  margin: 165px 0 0 0;

  width: 100%;
  height: 100%;

  padding: 0;

  & .image-gallery,
  & .carousel.carousel-slider,
  & .slider-wrapper,
  & .slider,
  & .slide,
  & video,
  & img {
    height: 100%;
  }

  & img {
    max-height: calc(100vh - 280px);
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 45%;
  }

  & .carousel {
    overflow: visible;

    & .control-dots {
      position: absolute;
      bottom: -20px;

      & .dot {
        background: #c4c4c4;

        &.selected,
        &:hover {
          background: rgba(250, 251, 246, 0.5);
        }
      }
    }
  }

  @media (max-width: 1300px) {
    margin: 95px 0 0 0;
  }
`;

const ImageContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 100%;
  position: relative;

  // &:before {
  //   display: block;
  //   content: "";
  //   width: 100%;
  //   padding-top: 56.25%;
  // }
  // & > .content {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  // }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Caption = styled.div`
  position: absolute;
  bottom: 40px;

  width: 100%;

  margin: 0 auto;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  text-align: center;

  z-index: 100;

  color: #1d1d1b;

  h1 {
    margin: 0;

    font-size: 32px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.01em;
  }
`;

const VideoWrapper = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ${props => props.ratio}%;
  }
  & > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    transition: 250ms opacity ease;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:hover img {
    opacity: ${props => (props.fadeOutImage ? 0 : 1)};
  }
`;

export const ImageGallery = ({
  data,
  caption,
  prevVideoUid,
  nextVideoUid,
  index,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [allGalleryContent, setAllGalleryContent] = useState([]);
  const [totalSlides, setTotalSlides] = useState(null);

  useEffect(() => {
    setTotalSlides(data.length);
    setAllGalleryContent(data);
    // setCaption(data[0].primary.caption.html);
  }, []);

  const updateCurrentSlide = index => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
      // setCaption(allGalleryContent[index].primary.caption.html);
    }
  };

  const galleryContent = data.map((image, index) => {
    return (
      <ImageContainer className="video" key={`single_image_${index}`}>
        {image.image.fluid !== null && (
          <img
            srcSet={image.image.fluid.srcSetWebp}
            src={image.image.fluid.srcWebp}
            alt={image.image.alt}
          />
        )}
      </ImageContainer>
    );
  });

  let imageCounter = [];
  for (let i = 0; i < totalSlides; i++) {
    imageCounter.push(
      <li
        key={i}
        onClick={() => setCurrentSlide(i)}
        className={currentSlide === i ? `active` : ``}
      />
    );
  }

  return (
    <GalleryContainer className="content-gallery">
      {galleryContent.length > 1 ? (
        <>
          <Carousel
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            infiniteLoop={true}
            useKeyboardArrows={true}
            className="image-gallery"
            selectedItem={currentSlide}
            onChange={index => updateCurrentSlide(index)}
            autoPlay={false}
            stopOnHover={false}
            interval={8000}
          >
            {galleryContent}
          </Carousel>

          {index === 0 && (
            <EventNavigation
              opacity={1}
              prevVideoUid={prevVideoUid}
              nextVideoUid={nextVideoUid}
              imageCounter={imageCounter}
              isImage={true}
            />
          )}

          {/* <Caption>
            <div dangerouslySetInnerHTML={{ __html: caption }} />
          </Caption> */}
        </>
      ) : (
        <>{galleryContent}</>
      )}
    </GalleryContainer>
  );
};
