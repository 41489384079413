import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import styled from "styled-components";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Components
import { EventMenu } from "../components/navigation/event-menu";
import { ImageGallery } from "../components/images/image-gallery";
import { EventVideo } from "../components/event/event-video";

const Page = styled.div`
  // & .title-bar {
  //   text-align: center;
  //   color: rgba(250, 251, 246, 0.5);

  //   padding: 25px;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;

  //   z-index: 11;

  //   & h1 {
  //     font-size: 36px;
  //     line-height: 36px;
  //     letter-spacing: 0.01em;
  //   }
  // }
`;

const DetailsContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 90px;

  top: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;

  padding: 30px 30px 0 30px;

  @media (max-width: 768px) {
    padding: 26px 20px 0 20px;

    position: relative;
  }

  color: rgba(250, 251, 246, 0.5);

  z-index: 100;

  & .video-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;

    h1 {
      font-size: 36px;
      line-height: 36px;

      margin: 0;

      @media (max-width: 1300px) {
        font-size: 28px;
        line-height: 36px;
      }

      @media (max-width: 900px) {
        font-size: 26px;
        line-height: 30px;
      }

      @media (max-width: 600px) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    & .director-name,
    & .brand-name,
    & .clip-name {
      font-family: "LDNBloomsburyOldStyle-Book", Times, "Times New Roman", serif;
      text-align: center;
      letter-spacing: 0.01em;
    }

    color: rgba(250, 251, 246, 0.5);
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  }
`;

const ContentContainer = styled.div`
  max-width: 715px;

  margin: 60px auto;
  padding: 0 20px;

  & p {
    color: rgba(253, 252, 252, 0.8);

    font-size: 18px;
    line-height: 128%;
    letter-spacing: 0.04em;

    @media (max-width: 1300px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  & .container {
    margin: 60px 0;
  }

  & img {
    max-width: 560px;
    margin: 0 auto;
  }
`;

const SingleEventContent = ({ data, setPageType, location }) => {
  useEffect(() => {
    setPageType("event");
  }, []);

  const allEvents = data.prismicEvents.data.body
    .filter(item => item.slice_type === `events`)
    .map(event => event.items)
    .flat()
    .filter(event => event.case_study.document !== null)
    .map(event => event.case_study.document.url);

  const videoPositionInReel = allEvents.indexOf(data.prismicEvent.url);
  const numberOfVideosInReel = allEvents.length;

  let nextVideoUid = null;
  let prevVideoUid = null;
  let nextVideoPositionInReel;
  let prevVideoPositionInReel;

  if (allEvents !== -1) {
    // Video exists
    nextVideoPositionInReel = videoPositionInReel + 1;
    prevVideoPositionInReel = videoPositionInReel - 1;
  } else {
    nextVideoPositionInReel = false;
    prevVideoPositionInReel = false;
  }
  if (nextVideoPositionInReel >= numberOfVideosInReel) {
    nextVideoPositionInReel = 0;
  }
  if (prevVideoPositionInReel < 0) {
    prevVideoPositionInReel = numberOfVideosInReel - 1;
  }
  if (nextVideoPositionInReel !== false && prevVideoPositionInReel !== false) {
    nextVideoUid = `${allEvents[nextVideoPositionInReel]}`;
    prevVideoUid = `${allEvents[prevVideoPositionInReel]}`;
  } else {
    nextVideoUid = false;
    prevVideoUid = false;
  }

  const content = data.prismicEvent.data.body1.map((content, index) => {
    if (content.slice_type === "slideshow") {
      return (
        <ImageGallery
          data={content.items}
          nextVideoUid={nextVideoUid}
          prevVideoUid={prevVideoUid}
          index={index}
        />
      );
    }

    if (content.slice_type === "video") {
      if (content.primary.vimeo_video.embed_url !== null) {
        return (
          <EventVideo
            video={content.primary.vimeo_video}
            playerId={`single_event_video_${content.id}`}
            nextVideoUid={nextVideoUid}
            prevVideoUid={prevVideoUid}
            index={index}
          />
        );
      } else {
        return null;
      }
    }

    if (content.slice_type === "text") {
      return (
        <ContentContainer
          key={`single_video_additional_content_${index}`}
          className="container caslon-doric"
          dangerouslySetInnerHTML={{
            __html: content.primary.text.html,
          }}
        />
      );
    }

    if (content.slice_type === "image") {
      return (
        <ContentContainer
          className="container"
          key={`single_video_additional_content_${index}`}
        >
          {content.primary.image.fluid !== null && (
            <img
              srcSet={content.primary.image.fluid.srcSetWebp}
              src={content.primary.image.fluid.srcWebp}
              alt={content.primary.image.alt}
            />
          )}
        </ContentContainer>
      );
    }
  });
  return (
    <Page>
      {/* <DetailsContainer> */}
      {/* <div className="title-bar"> */}
      {/* <div
          className="container caslon-doric"
          dangerouslySetInnerHTML={{
            __html: data.prismicEvent.data.title.html,
          }}
        /> */}
      {/* </div> */}
      {/* </DetailsContainer> */}

      <DetailsContainer>
        <EventMenu
          title={data.prismicEvent.data.title.html}
          // director={director}
          // directorUid={directorUid}
          // brand={brand}
          // clip={clip}
          // showInfo={checkAdditionalVideoContentExists !== null}
          // backButtonURL={backButtonURL}
        />
      </DetailsContainer>

      {content}
    </Page>
  );
};

const SingleEvent = ({ data, location }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <SingleEventContent
        setPageType={setPageType}
        data={data}
        location={location}
      />
    )}
  </PageTypeConsumer>
);

export default withPreview(SingleEvent);

export const query = graphql`
  query SingleEvent($uid: String!) {
    prismicEvents {
      data {
        body {
          ... on PrismicEventsBodyVideo {
            slice_type
          }
          ... on PrismicEventsBodyText {
            slice_type
          }
          ... on PrismicEventsBodyBioText {
            slice_type
          }
          ... on PrismicEventsBodyTextWithDownloadButton {
            slice_type
          }
          ... on PrismicEventsBodyEvents {
            id
            slice_type
            items {
              case_study {
                document {
                  ... on PrismicEvent {
                    id
                    url
                    type
                    uid
                  }
                  ... on PrismicVideo {
                    id
                    url
                    type
                    uid
                  }
                }
              }
            }
          }
          ... on PrismicEventsBodyMediumText {
            slice_type
          }
          ... on PrismicEventsBodySmallText {
            slice_type
          }
          ... on PrismicEventsBodyLogos {
            slice_type
          }
        }
      }
    }
    prismicEvent(uid: { eq: $uid }) {
      url
      data {
        title {
          html
          text
        }
        excerpt {
          text
        }
        body1 {
          ... on PrismicEventBody1Video {
            id
            slice_type
            primary {
              vimeo_video {
                height
                embed_url
                url
                width
                html
              }
            }
          }
          ... on PrismicEventBody1Slideshow {
            id
            slice_type
            items {
              image {
                alt
                dimensions {
                  height
                  width
                }
                fluid {
                  srcWebp
                  srcSetWebp
                  aspectRatio
                }
              }
            }
          }
          ... on PrismicEventBody1Image {
            id
            slice_type
            primary {
              image {
                alt
                fluid {
                  srcWebp
                  srcSetWebp
                  aspectRatio
                }
                dimensions {
                  height
                  width
                }
              }
              caption {
                html
              }
            }
          }
          ... on PrismicEventBody1Text {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
        }
      }
    }
  }
`;
