import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import "../styles/plyr.css";
import { useIdle } from "react-use";
import { use100vh } from "react-div-100vh";

// Components
import { EventNavigation } from "./event-navigation";
import "../styles/range-slider.css";

const PageContainer = styled.div`
  padding: 0;
  // min-height: ${props => props.minHeight}px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;

  background-color: #000;
  position: relative;
  z-index: 10;

  @media (max-width: 768px) {
    align-content: flex-start;
  }
`;

const VideoContainer = styled.div`
  margin: 0 auto;

  & .plyr__video-wrapper {
    width: 100vw;
    max-height: 100%;
    max-width: ${props => props.aspectRatio}vh;
  }
`;

const DetailsContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 90px;

  top: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;

  padding: 30px 30px 0 30px;

  @media (max-width: 768px) {
    padding: 26px 20px 0 20px;

    position: relative;
  }

  transition: 250ms opacity ease;
  opacity: ${props => props.opacity};

  z-index: 100;

  & .video-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;

    h1 {
      font-size: 36px;
      line-height: 36px;

      margin: 0;

      @media (max-width: 1300px) {
        font-size: 28px;
        line-height: 36px;
      }

      @media (max-width: 900px) {
        font-size: 26px;
        line-height: 30px;
      }

      @media (max-width: 600px) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    & .director-name,
    & .brand-name,
    & .clip-name {
      font-family: "LDNBloomsburyOldStyle-Book", Times, "Times New Roman", serif;
      text-align: center;
      letter-spacing: 0.01em;
    }

    color: rgba(250, 251, 246, 0.5);
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  }
`;

export const EventVideo = ({
  video,
  playerId,
  nextVideoUid,
  prevVideoUid,
  index,
}) => {
  const [videoPlyr, setVideoPlyr] = useState(null);
  const [pageOpacity, setPageOpacity] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const pageHeight = use100vh();

  const isIdle = useIdle(1500);

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Plyr = require("plyr");

      const player = new Plyr(`#${playerId}`, {
        autoplay: true,
        muted: false,
        clickToPlay: true,
        loop: { active: false },
        hideControls: true,
        invertTime: false,
        iconUrl: "/icons/plyr-sprite.svg",
        iconPrefix: "video",
        fullscreen: {
          iosNative: true,
        },
      });

      setVideoPlyr(player);
    }
  }, [playerId, setVideoPlyr]);

  useEffect(() => {
    if (isIdle === false) {
      setOpacity(1);
    }

    if (videoPlyr !== null) {
      videoPlyr.on("play", event => {
        setOpacity(0);
      });

      videoPlyr.on("pause", event => {
        setOpacity(1);
      });

      videoPlyr.on("controlshidden", event => {
        setOpacity(0);
      });

      videoPlyr.on("controlsshown", event => {
        setOpacity(1);
      });
    }
  }, [videoPlyr, isIdle, setOpacity]);

  return (
    <>
      <Helmet>
        <link rel="preconnect" as="video" href={video.embed_url} />
      </Helmet>

      <PageContainer onMouseMove={() => setOpacity(1)} minHeight={pageHeight}>
        <VideoContainer aspectRatio={(video.width / video.height) * 100}>
          <div
            className="plyr__video-embed"
            id={playerId}
            data-plyr-config='{ "controls": ["play", "progress", "current-time", "duration", "fullscreen"] }'
            dangerouslySetInnerHTML={{
              __html: video.html,
            }}
          />
        </VideoContainer>

        {index === 0 && (
          <EventNavigation
            opacity={opacity}
            prevVideoUid={prevVideoUid}
            nextVideoUid={nextVideoUid}
          />
        )}
      </PageContainer>
    </>
  );
};
