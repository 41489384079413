import React from "react";
import styled from "styled-components";

const Button = styled.button``;
const Icon = styled.svg`
  opacity: 0.5;
`;

export const InfoIcon = ({ fill }) => (
  <Button
    onClick={() =>
      document.querySelector("#additional-video-content").scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
  >
    <Icon
      width="47"
      height="40"
      viewBox="0 0 47 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.5099 15.8248C44.4373 15.8248 46.04 14.8442 46.04 12.4773V3.34756C46.04 0.994127 44.3967 0 42.5099 0C40.6231 0 39 0.980601 39 3.34756V12.4773C39 14.8307 40.5825 15.8248 42.5099 15.8248ZM42.4963 1.80566C43.2064 1.80566 43.6731 2.10998 43.6731 3.06352V12.7613C43.6731 13.7149 43.2064 14.0192 42.4963 14.0192C41.7863 14.0192 41.3196 13.7149 41.3196 12.7613V3.06352C41.3196 2.10998 41.7863 1.80566 42.4963 1.80566ZM0 39.4191H2.36697V24H0V39.4191ZM14.7272 31.4292L12.5158 22.2386C12.3016 21.316 12.1409 20.5004 12.012 19.8462L12.012 19.8462C11.9822 19.6948 11.9541 19.552 11.9274 19.4186H11.7854C11.7915 19.6381 11.8012 19.8784 11.8119 20.1411C11.8367 20.7525 11.8665 21.4853 11.8665 22.3604V31.4292H10V16.0101H12.7389L14.504 23.8617C14.6674 24.6107 14.8034 25.3962 14.9181 26.0588L14.9181 26.0588L14.9181 26.0589C14.9743 26.3834 15.0254 26.6784 15.0721 26.9252H15.2141C15.21 26.7656 15.2051 26.5962 15.1999 26.4155C15.1791 25.7002 15.1532 24.8088 15.1532 23.6588V16H17.0198V31.4292H14.7272ZM27.4146 14.7027H29.8293V16.6594H27.4146V23.82H25V8H30.6619V9.93587H27.4146V14.7027Z"
        fill={fill}
      />
    </Icon>
  </Button>
);
