import React, { useContext, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Media from "react-media";
// Context
import { PageTypeConsumer } from "../context/page-type";
import WindowWidthContext from "../context/window-width";

// Components
import { DesktopSlideOutMenu } from "./desktop-slide-out-menu";
import { MobileSlideOutMenu } from "./mobile-slide-out-menu";

// Icons
import { StudiosIcon } from "../icons/studios-icons";
import { BackIcon } from "../icons/back-icon";
import { InfoIcon } from "../icons/info-icon";

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  position: relative;

  color: ${props => props.color};
`;

const Item = styled.div`
  position: absolute;

  transition: 250ms opacity ease;
  opacity: ${props => props.opacity};
`;

const ItemLeft = styled(Item)`
  left: 0;
`;

const ItemCentred = styled(Item)`
  left: 50%;
  transform: translateX(-50%);
`;

export const EventMenu = ({
  showInfo,
  location,
  director,
  directorUid,
  backButtonURL,
  title,
}) => {
  const windowWidth = useContext(WindowWidthContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <PageTypeConsumer>
      {({ pageType }) => {
        let textColor = `rgba(250,251,246,0.5)`;
        if (pageType === `video` || pageType === `event`) {
          textColor = `rgba(250,251,246,0.5)`;
        } else if (
          pageType === `directors` ||
          pageType === `experiential` ||
          pageType === `events` ||
          pageType === `textpage`
        ) {
          textColor = `#fff`;
        } else {
          textColor = `#1D1D1B`;
        }

        return (
          <Nav color={textColor}>
            {/* <ItemLeft> */}
            {/* {showInfo ? (
                <InfoIcon fill={textColor} />
              ) : (
                <BackIcon backButtonURL={backButtonURL} fill={`#fff`} />
              )} */}
            {/* </ItemLeft> */}

            <ItemLeft>
              <StudiosIcon fill={`#fff`} />
            </ItemLeft>

            <ItemCentred>
              <div className="video-details">
                <div
                  className="director-name"
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />
              </div>
            </ItemCentred>

            <Media
              queries={{ medium: "(max-width: 768px)" }}
              defaultMatches={{ medium: windowWidth === 768 }}
              render={() => (
                <MobileSlideOutMenu
                  location={location}
                  textColor={textColor}
                  setIsMobileMenuOpen={setIsMobileMenuOpen}
                />
              )}
            />
            <Media
              queries={{ medium: "(min-width: 769px)" }}
              defaultMatches={{ medium: windowWidth === 769 }}
              render={() => (
                <DesktopSlideOutMenu
                  location={location}
                  textColor={textColor}
                />
              )}
            />
          </Nav>
        );
      }}
    </PageTypeConsumer>
  );
};
